import { useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { auth } from "~/libs/db/firebase";
import { Loading } from "~/components/common/Loading";
import AuthError from "./AuthError";
import useAuthState from "~/hooks/useAuthState";
import { getUserTokenClaims } from "~/libs/db/user";
import { useBaseStore, useUserStore } from "~/libs/store";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { LOCAL_STORAGE_ENUM } from "~/libs/constants";

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const [preURL, setPreURL] = useLocalStorage(LOCAL_STORAGE_ENUM.PRE_URL, "");

  useEffect(() => {
    if (!loading && !error) {
      setPreURL(
        `${location.pathname}${
          location.search ? encodeURI(location.search) : ""
        }`
      );
      // 이전 history stack으로 이동
      if (!user) {
        navigate(`/login?pre=${location.pathname}`, { replace: true });
        const isLogout = useUserStore.getState().isLogout;
        if (isLogout) {
          useUserStore.setState({ isLogout: false });
          return;
        }
        useBaseStore.setState({
          notify: { message: "먼저 로그인이 필요해요." },
        });
        return;
      }
      // 유저 토큰 조회
      getUserTokenClaims(user)
        .then((tokenClaims) => {
          const { token, claims } = tokenClaims;
          if (!claims?.email_verified) {
            navigate("/email", { replace: true });
            return;
          }
          if (!token) {
            navigate("/login", { replace: true });
            return;
          }
        })
        .catch((e) => {
          console.log("auth route email e: ", e);
        });
    }
    return () => {};
  }, [user, loading, error]);

  if (loading) return <Loading />;
  if (error) return <AuthError />;
  if (user) return children;

  return (
    <Navigate
      replace
      to="/login"
      state={{ from: location, message: "먼저 로그인이 필요해요." }}
    />
  );
};

export default AuthRoute;
