import {
  firestore,
  auth,
  userCollection,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
  writeBatch,
  signOut,
  User,
  ParsedToken,
  signInAnonymously,
  getDocs,
} from "~/libs/db/firebase";
import { useBaseStore, useSpaceStore, useUserStore } from "~/libs/store";
import { defaultUserType } from "~/data/user/constants";
import { TypeOfUser } from "~/types";
import { ipAPI, userAPI } from "~/libs/api";
import { FirebaseLogger } from "~/libs/ga";
import { isAnonymousUser } from "../permissions";
import { defaultAlert } from "../constants";

interface IUserTokenClaims {
  token?: string;
  claims?: ParsedToken;
}

// 유저 token & claims 가져오기
export const getUserTokenClaims = async (
  user: User
): Promise<IUserTokenClaims | null> => {
  try {
    // true for force reload
    const idTokenResult = await user?.getIdTokenResult(true);
    if (idTokenResult) {
      const claims = idTokenResult?.claims;
      const token = idTokenResult?.token;
      return { token, claims };
    }
    return null;
  } catch (e) {
    console.error("user claims e: ", e);
    return null;
  }
};

// 유저 정보 저장
export const userSetByUid = async (user: User): Promise<void> => {
  try {
    let userData = await userGetByUid(user.uid);
    const providerId = user?.providerData?.[0]?.providerId;
    if (!userData) {
      // NOTE: 구글 로그인으로 최초 진입 (DB 없는 상태)
      if (providerId?.toLowerCase?.().includes?.("google")) {
        userData = await userSignUpByGoogle(user);
      }
    } else {
      FirebaseLogger("로그인", {
        event: "완료",
        timestamp: new Date().toLocaleString(),
      });
    }
    if (import.meta.env.PROD) {
      try {
        await userCreateSignInIp(user.uid);
      } catch (e) {
        console.log("add ip e: ", e);
      }
    }

    const { token, claims } = await getUserTokenClaims(user);
    useUserStore.setState({
      user: {
        ...userData,
        uid: user.uid,
        emailVerified: claims?.email_verified || null,
        idToken: token,
      } as TypeOfUser.User,
      userAuth: user,
    });
  } catch (e) {
    console.log("user set e: ", e);
    useBaseStore.setState({
      notify: {
        message:
          "서버에서 회원 정보를 불러올 수 없어요.<br/>잠시 후에 다시 시도해 주세요.",
      },
    });
  }
};

// 구글 아이디로 로그인
const userSignUpByGoogle = async (user: User): Promise<TypeOfUser.User> => {
  // 구글 아이디로 회원가입 로깅 + DB 저장
  const timeStamp = new Date().getTime();
  const data = {
    ...defaultUserType,
    uid: user.uid,
    name: user.displayName,
    displayName: user.email,
    email: user.email,
    emailVerified: user.emailVerified,
    profileImage: user.photoURL,
    provider: "GOOGLE" as TypeOfUser.UserProvider,
    age: "", // 연령대
    birthday: "", // 생년월일
    gender: "Unknown" as TypeOfUser.UserGender, // 성별
    create: timeStamp, // 생성일
    lastUpdate: timeStamp, // 수정일
    marketing: true,
  };
  FirebaseLogger("회원가입", {
    event: "완료",
    timestamp: new Date().toLocaleString(),
  });

  await userSignUp(user.uid, data, true);

  return data;
};

/**
 * 유저 신규 DB 등록
 * @param {string} uid
 * @param {TypeOfUser.User} data
 * @param {boolean} merge
 */
export const userSignUp = async (
  uid: string,
  data: TypeOfUser.User,
  merge: boolean
): Promise<void> => {
  await setDoc(doc(userCollection, uid), { ...data }, { merge: merge || true });
};

/**
 * 유저 DB UID로 바로 가져오기
 * @param {string} uid
 * @returns {User} user
 */
export const userGetByUid = async (
  uid: string
): Promise<TypeOfUser.User | null> => {
  const userDoc = await getDoc(doc(userCollection, uid));
  if (!userDoc.exists()) return null;
  return {
    uid,
    ...userDoc.data(),
  };
};

/**
 * 유저 정보 업데이트
 * @param {string} uid
 * @param {any | TypeOfUser.User} data
 */
export const userUpdate = async (uid: string, data: any): Promise<void> => {
  await setDoc(
    doc(userCollection, uid),
    {
      ...data,
      lastUpdate: new Date().getTime(),
    },
    { merge: true }
  );
};

/**
 * 유저 로그인 IP 저장
 * @param {string} uid
 */
export const userCreateSignInIp = async (uid: string): Promise<void> => {
  try {
    if (import.meta.env.DEV) return;

    const ipInfo = await ipAPI.getIp();
    if (!ipInfo || typeof ipInfo === "string") return;

    await addDoc(collection(firestore, "user", uid, "ip"), { ...ipInfo });
  } catch (e) {
    console.log("login ip e:", e);
  }
};

/** 유저 로그아웃 */
export const userSignOut = async (): Promise<void> => {
  try {
    useUserStore.setState({ isLogout: true });
    await signOut(auth);
  } catch (e) {
    useBaseStore.setState({
      notify: {
        message:
          "로그아웃 중에 오류가 있어요.<br/>잠시 후에 다시 시도해 주세요.",
      },
    });
  }
};

/**
 * 유저 계정탈퇴
 * TODO: - 사용 중지 처리이력 admin 확인 가능하도록
 */
export const userLeave = async (uid: string, select: string): Promise<void> => {
  try {
    const batch = writeBatch(firestore);
    const autoAuthId = doc(collection(firestore, "user", uid, "auth")).id;
    const authRef = doc(firestore, "user", uid, "auth", autoAuthId); // 계정 정보 저장
    const userRef = doc(firestore, "user", uid);

    const timestamp = new Date().getTime();
    batch.set(authRef, { status: 2, create: timestamp, select });
    batch.update(userRef, { status: 2, lastUpdate: timestamp });

    const [dbRes, schemaRes] = await Promise.all([
      batch.commit(),
      userAPI.userDisabled({
        uid,
        disabled: true,
        updateType: "disable",
      }),
    ]);
    if (schemaRes?.status === "success") {
      await signOut(auth);
      useBaseStore.setState({
        notify: {
          message: "정상적으로 회원 탈퇴되었어요.<br/>꼭 다시 만나요.",
        },
      });
    } else {
      useBaseStore.setState({
        notify: {
          message: `계정 정보를 수정하는데 오류가 있어요.<br/>${
            schemaRes?.msg || ""
          }`,
        },
      });
    }
  } catch (e) {
    console.log("leave e: ", e);
    useBaseStore.setState({
      notify: {
        message: "일시적인 오류가 있어요.<br/>잠시 후에 다시 시도해주세요.",
      },
    });
  }
};

// 블랙리스트 IP 추가
export const addToIPBlackList = async (): Promise<void> => {
  try {
    const ipInfo = await ipAPI.getIp();
    if ((import.meta.env.PROD && !ipInfo) || typeof ipInfo === "string") return;
    await addDoc(collection(firestore, "blacklist"), { ...ipInfo });
  } catch (e) {
    console.log("bl e: ", e);
  }
};

/**
 * 유저 익명 로그인
 */
export const userSignInWithAnonymous = async () => {
  await signInAnonymously(auth);
};

/**
 * 유저 익명 로그인 시, 버튼 클릭 처리
 */
export const onHandleAnonym = async () => {
  const space = useSpaceStore.getState()?.selectedSpace;
  const isAnonymous = isAnonymousUser();
  if (isAnonymous) {
    const confirmAlert = await defaultAlert(
      {
        html: "<h1>기능을 모두 사용하려면 로그인이 필요해요.</h1>",
        footer: "",
        confirmButtonText: "로그인 하기",
        cancelButtonText: "다음에",
      },
      true
    );
    // 로그인 여부 처리
    if (confirmAlert.isConfirmed) {
      await userSignOut();

      if (space?.id) {
        window.location.href = `/login?pre=/space/board/${space.id}`;
      } else {
        window.location.href = "/login";
      }
    }
  }
};

/**
 * 유저 익명 DB 처리
 */
export const setUserByAnonym = (uid: string) => {
  useUserStore.setState({
    user: {
      uid,
      name: "익명",
      email: "익명",
      displayName: "익명",
      create: new Date().getTime(),
      lastUpdate: new Date().getTime(),
    },
    isAnonymous: true,
  });
};

// 선생님 인증 요청 정보 저장
export const teacherVerifyUpdate = async (uid: string, data: any) => {
  await setDoc(doc(collection(firestore, "verify"), uid), data, {
    merge: true,
  });
};

// 선생님 인증 요청 정보 목록 조회
export const teacherVerifyReads = async () => {
  const docs = await getDocs(collection(firestore, "verify"));
  if (docs.empty) return [];
  return docs.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};
