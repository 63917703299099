import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  TypeOfSpace,
  TypeOfSpaceCollection,
  TypeOfSpaceFeature,
  TypeOfUser,
} from "~/types";

export const initialStatus = {
  scrollTo: null as { type: string; id: string } | null, // scrollTo(스페이스 내에서 화면 이동)
  // ### 튜토리얼 데이터 ###
  tutorial: null, // 튜토리얼 데이터
  isCloseTutorial: false, // 튜토리얼 닫기
  isTutorialRun: false, // 튜토리얼 시작 여부 저장
  // ### 튜토리얼 데이터 ###

  // ### 스페이스 생성 시(수업 설계 정보) ###
  isSpaceMakeDialog: false, // 스페이스 생성 모달 show/hide
  // ### 스페이스 생성 시(수업 설계 정보) ###

  // ### 스페이스 내 기능 UI 관리 ###
  isSide: true, // 사이드 내비게이션 show/hide
  isSpaceSetting: false, // 스페이스 설정 show/hide
  isBoardSetting: false, // 보드 설정 show/hide
  isSpaceHistory: false, // 스페이스 기록 show/hide
  cardHistory: "" as TypeOfSpace.SpaceCard, // 카드 기록 show/hide
  isSpaceInfo: false, // 스페이스 정보 show/hide
  isSpaceNotice: false, // 스페이스 공지사항 show/hide
  isSpaceShare: false, // 스페이스 공유 show/hide
  isSpaceSlide: false, // 스페이스 발표 show/hide
  isSpaceDashboard: false, // 스페이스 현황 show/hide
  isPDFSlide: false as boolean, // 스페이스 PDF 발표 show/hide with pdf uploader management
  isCardSlide: "" as string, // 스페이스 카드 발표 show/hide with
  isCardPin: false as boolean, // 카드 핀
  isSpaceChat: false, // 스페이스 채팅 show/hide
  isSpaceQuestion: false, // 스페이스 응답 리스트 show/hide
  isSpaceQuestionBuilder: "" as "" | "create" | "update", // 스페이스 응답 빌더 show/hide & 추가, 삭제 구분
  isSpaceExcelExporter: false as boolean, // 스페이스 보드, 카드 정보 엑셀 내보내기
  isCardDiscuss: false as boolean, // 카드 논의 show/hide
  cardDiscuss: null as any, // 카드 논의
  spaceOnlineMember: [] as any[], // 스페이스에 온라인인 멤버 확인
  formImage: { url: "", file: null } as any, // 질문 설명에 이미지 첨부하기
  spaceQuestionBuilderOption:
    null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // builder option 값 저장
  isEditorFocus: false, // 카드 에디터 포커스 여부(모바일 처리를 위해 사용)
  isSpaceEditorSave: false as boolean, // 카드 에디터 저장 요청을 위해 (key 값이 변해서 날아감...!)
  isSpaceDrawing: false as boolean, // 카드 에디터 드로잉 켜져있는지 확인
  isSpaceSlideExportMode: false as boolean, // 스페이스 슬라이드 exportMode 켜졌는지 확인
  isSpaceFullscreen: false as boolean, // 스페이스 풀스크린 모드 여부 확인
  spaceCardCopyID: null as {
    cid: string;
    bid: string;
    graphic: TypeOfSpace.BoardGraphicType;
  } | null, // 카드 복제용 cid
  isSpaceEnterDialog: false as boolean, // 스페이스 접속 팝업
  isSpacePinDialog: false as boolean, // 스페이스 핀코드 수정 팝업
  isSpaceGroupDialog: false as boolean, // 스페이스 그룹 수정 팝업
  // ### 스페이스 내 기능 UI 관리 ###

  // ### v2 스페이스 카드 리스트 리스닝 ###
  spaceCardList: null as TypeOfSpace.SpaceColumnsBoard[] | null,
  // ### v2 스페이스 카드 리스트 리스닝 ###

  // ### 현재 선택된 항목 리스닝 ###
  selectedSpace: null as TypeOfSpace.Space | null, // DB Listening 스페이스
  selectedSpaceOwner: null as TypeOfUser.Profile, // 스페이스 소유자 정보 저장
  selectedSpaceColumns: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // RTDB Listening 스페이스 컬럼
  selectedSpaceUsage: [] as TypeOfSpace.SpaceUsage[] | null, // RTDB Listening 스페이스 사용량
  selectedBoard: null as TypeOfSpace.SpaceColumnsBoard | null, // 선택된 스페이스 보드
  selectedCard: null as TypeOfSpace.SpaceColumnsCard | null, // 선택된 카드
  // ### 현재 선택된 항목 리스닝 ###

  // ### 스페이스 내에서 검색 정보 ###
  isSearchMobile: false as boolean, // 모바일용 검색바 Show/Hide
  search: null as TypeOfSpace.PermissionUser, // 검색어 카드제목|작성자
  searchList: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // 스페이스 내에서 검색결과 정보
  searchOptionMine: false as boolean, // 내 카드만 보기
  searchOptionGroup: false as boolean, // 그룹 카드만 보기
  searchOptionColor: "" as string, // 색상으로 검색
  searchOptionEmoji: "" as string, // 이모지 등록 여부로 검색
  searchOptionStartDate: "" as string, // 시작일로 검색
  searchOptionEndDate: "" as string, // 종료일로 검색
  searchOptionDiscussSize: 0 as number, // 댓글 개수로 검색
  // ### 스페이스 내에서 검색 정보 ###

  // ### 카드 정보 ###
  isLockAlertOpen: false as boolean, // 잠금 해제 경고 노출 여부
  card: null as TypeOfSpace.SpaceCard | null, // DB Listening 카드
  // ### 카드 정보 ###

  // ### 단일 이미지 크게 보기 ###
  imageZoom: "" as string, // Zoom 이미지 URL
  // ### 단일 이미지 크게 보기 ###

  // ### 발표 정보  ###
  slide: null as TypeOfSpaceFeature.SpaceSlideType | null, // 현재 슬라이드 데이터
  formSlide: null as any, // 질문형 카드 슬라이드
  question: null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // 현재 질문 데이터
  questionAnalytics: null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // 현재 질문 데이터 분석기 열기
  // ### 발표 정보  ###

  // ### 뒤로가기 버튼 show/hide용 카드 스크롤 체크 ###
  isCardHeaderVisible: true as boolean, // 카드 헤더 보이는지 여부
  // ### 뒤로가기 버튼 show/hide용 카드 스크롤 체크 ###

  // ### 파일 첨부 크게 보기 ###
  isSpaceEvaluate: false as boolean, // 유저 활동 정보 평가 show/hide
  isSpaceAttach: false as boolean, // 스페이스 카드 내에 파일 첨부 show/hide
  isLeftAttach: false as boolean, // 첨부파일 왼쪽에서 크게보기
  fullscreenFileViewerScale: {
    number: 1.0,
    text: `scale(1.0)`,
  }, // 첨부파일 좌측에서 크게보기 스케일 조절
  fileViewer: null as TypeOfSpace.SpaceCardAttach | null, // 카드 파일 크게 보기
  isSpaceViewer: false as boolean, // 스페이스 뷰어 모드 접속
  isLeftCardViewer: false as boolean, // 지금까지의 카드 작성 기록 보기 show/hide
  leftCardViewer: {
    uid: "",
    exceptCards: [],
  } as {
    uid: string;
    exceptCards: any[];
  }, // 지금까지의 카드 작성 기록 보기(카드 좌측 뷰어 정보)
  isSideFullscreen: false, // 카드 좌측보기 풀스크린 여부
  // ### 파일 첨부 크게 보기 ###

  // ### 스페이스 데이터 전송용 스페이스/보드 목록 ###
  spaceOwnList: [] as any[], // 스페이스 소유 리스트
  boardOwnList: null as { [key: string]: any[] }, // 스페이스별 보드 소유 리스트
  // ### 스페이스 데이터 전송용 스페이스/보드 목록 ###

  // ### 복구 ###
  isSpaceRestore: false, // 스페이스 복구 show/hide
  restoreList: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // 스페이스 복구 미리보기 리스트
  isRestorePreview: {
    index: 0,
    create: 0,
  } as {
    index: number;
    create: number;
  }, // 스페이스 복구 미리보기 시점
  isCardRestore: false, // 카드 복구 show/hide
  // ### 복구 ###

  // ### 학생 인사이트 열기 ###
  isSpaceInsight: false as boolean, // 학습자용 스페이스 인사이트
  // ### 학생 인사이트 열기 ###

  // ### 투표 관련 정보 ###
  isSpaceVote: "" as string, // 스페이스 투표함 에디터 show/hide & update id
  spaceVoteList: [] as TypeOfSpaceFeature.Vote[], // 스페이스 투표 정보
  spaceVote: null as TypeOfSpaceFeature.Vote, // 투표하기
  spaceVoteViewer: null as TypeOfSpaceFeature.Vote, // 투표결과 상세
  // ### 투표 관련 정보 ###

  // ### 카드 첨부 정보 ###
  isEmbedCard: {
    src: "",
    isShow: false,
  }, //
  // ### 카드 첨부 정보 ###

  isFullScreen: false, // 카드 풀스크린 여부

  // ### 스페이스 홈 layout 관련 상태 정보 ###
  spaceList: {
    ownList: [],
    recentList: [],
  } as {
    ownList: TypeOfSpace.Space[] | []; // 내가 만든 스페이스 리스트
    recentList: TypeOfSpace.SpaceUserLatestData[] | []; // 최근 접속 스페이스 리스트
  }, // 스페이스 리스트 검색
  spaceSearchList: {
    ownList: [],
    recentList: [],
  } as {
    ownList: TypeOfSpace.Space[] | []; // 내가 만든 스페이스 리스트
    recentList: TypeOfSpace.SpaceUserLatestData[] | []; // 최근 접속 스페이스 리스트
  }, // 스페이스 리스트 검색
  viewMode: "grid" as string, // grid / table
  // ### 스페이스 홈 layout 관련 상태 정보 ###

  // ### 북마크 정보 ###
  bookmarks: [], // 북마크 DB저장 형태
  bookmarkSpaceList: [], // 북마크 스페이스 전체 데이터 형태
  // ### 북마크 정보 ###

  // ### 폴더 정보, 폴더 관리용 모달, 폴더 삭제용 리스트 정보 ###
  collections: [], // 컬렉션 리스트
  collectionSpaceList: [], // 특정 컬렉션(폴더) 접속 시 표시되는 목록
  cntCollection: null as TypeOfSpaceCollection.SpaceCollection, // 현재 보고 있는 컬렉션 정보
  isCollectionDialog: false, // 컬렉션 신규 생성 모달 show/hide
  isCollectionEditDialog: false, // 컬렉션 정보 수정 모달 show/hide
  isCollectionSpaceDialog: false, // 컬렉션 내의 스페이스 추가 신규 생성 모달 show/hide
  isCollectionSpaceDelete: false, // 컬렉션 내의 스페이스 삭제 체크박스 시작 여부
  collectionSpaceDeleteList: [], // 컬렉션 내의 스페이스 삭제 체크박스 선택된 리스트
  // ### 폴더 정보, 폴더 관리용 모달, 폴더 삭제용 리스트 정보 ###

  // ### 북마크 검색 목록 ###
  bookmarkSearchList: [] as any[],
  // ### 북마크 검색 목록 ###

  isMembersGroupDialog: false, // 구성원의 직접 그룹 생성
  // isCollectionSpaceAdd: [], // 스페이스 특정 폴더로 이동 체크박스 버튼 시작 여부
  // collectionSpaceAddList: [], // 스페이스 홈 화면에서 특정 컬렉션으로 스페이스 이동하기 위한 리스트

  isMathEditorDialog: false as boolean, // 수식 입력 에디터 show/hide
  isSpaceQRCodeDialog: false as boolean, // 스페이스 접속 QR코드 show/hide
};

const store = () => initialStatus;

const useSpaceStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useSpaceStore;
